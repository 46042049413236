.form-sub-label {
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-sm;
  color: $color-main-2;
}

.has-error .clp-input .ant-input-group .ant-input,
.has-error .clp-input .ant-input-group .ant-input-group-addon,
.has-error .clp-input .ant-input-group .ant-input[disabled]:hover {
  border-color: $color-antd-error !important;
}
