.link {
  color: $color-main-1 !important;
  text-decoration-line: underline;
  letter-spacing: 1.022px;
  
  &_bold {
    font-weight: bold;
    letter-spacing: unset !important;
  }
}
