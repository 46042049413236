$header-height: 112px;
$footer-height: 211px;

.clp-main {
  padding-top: $spacer-13;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});

  @media (min-width: $screen-md) {
    padding-top: 100px;
  }
}

.clp-header {
  overflow: unset;
}
