@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,700&display=swap');

.clp-logo img {
  width: 80px;
  height: 80px;

  @media (max-width: 369px) {
    width: 65px;
    height: 65px;
    margin-right: 0;
  }
}
.clp-logo__label {
  font-family: 'Merriweather', serif;
  font-style: italic;
  text-transform: capitalize !important;
  font-size: 24px;

  @media (max-width: 369px) {
    font-size: 18px;
  }
}

.clp-logo__sub-label {
  font-style: normal;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;

  @media (max-width: 369px) {
    font-size: 9px;
  }
}
