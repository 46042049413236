body {
  background: $color-backgroundle;
}

input {
  outline: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px $color-backgroundle !important;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: inherit;
  font-weight: inherit;
}

.break-word {
  word-break: break-word !important;
}

.position-relative {
  position: relative !important;
}
