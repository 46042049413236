.pagination-light {
  margin-top: $spacer-4;
  text-align: right;

  .ant-pagination-item-active {
    border-color: $color-main-1;
    background: $color-main-1;
    color: white;

    a {
      color: inherit;
    }
  }

  .ant-pagination-item:hover a {
    color: inherit;
  }
}
