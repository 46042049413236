.bordered_bottom {
  border-bottom: 1px solid $color-main-2;

  &_light {
    border-color: $color-border-light;
  }
}

.first-letter {
  &_lowercase {
    text-transform: lowercase;
  }

  &_capitalize {
    text-transform: capitalize;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.t {
  &_white-space {
    &_normal {
      white-space: normal;
    }
  }
  
  &_decoration_none {
    text-decoration: none !important;
  }

  &_spacing {
    &_lg {
      letter-spacing: 1.2px;
    }
  }

  &_normal {
    font-weight: normal;
  }

  &_bold {
    font-weight: 800;
  }
}

.opacity {
  &_light {
    opacity: 0.75;
  }
}

.color {
  &_primary {
    color: $color-main-1;
  }

  &_default {
    color: $color-main-2;
  }

  &_light {
    color: $color-label-light;
  }
}

.d-flex {
  display: flex;

  &__item {
    flex: 1;
  }

  &_align {
    &_center {
      align-items: center;
    }

    &_end {
      align-items: flex-end;
    }
  }

  &_justify {
    &_between {
      justify-content: space-between;
    }
  }

  &_direction {
    &_column {
      flex-direction: column;
    }

    &_reverse {
      flex-direction: row-reverse;
    }
  }
}

.float {
  &_r {
    float: right;
  }

  &_l {
    float: left;
  }
}

.mob-block {
  &_center {
    @media (max-width: $screen-md - 1) {
      margin: 0 auto;
    }
  }
}

.pl-200 {
  padding-left: 200px;
}
