$clp-datepicker-border-color: $color-main-2;
$clp-datepicker-icon-color: $color-main-2;

$clp-datepicker-day-hover-color: #0f0d181a;
$clp-datepicker-today-color: $color-primary;

$clp-datepicker-selected-day-color: $color-primary;

$clp-datepicker-input-default-color: $color-primary;

.clp-datepicker {
  svg path {
    color: $clp-datepicker-icon-color;
  }

  .ant-calendar-selected-day .ant-calendar-date,
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    background: $clp-datepicker-selected-day-color;
    color: white;
    border-color: $clp-datepicker-selected-day-color;
  }

  .ant-calendar-today:not(.ant-calendar-selected-day) .ant-calendar-date {
    color: $clp-datepicker-today-color;
    border-color: $clp-datepicker-today-color;
  }

  .ant-calendar-date,
  .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-decade {
    &:hover {
      background: $clp-datepicker-day-hover-color;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-today-btn,
  .ant-calendar-my-select a {
    color: $clp-datepicker-today-color;
  }

  &__input {
    .ant-calendar-picker-input.ant-input {
      color: $clp-datepicker-input-default-color;
      background: transparent;
      box-shadow: none;
      outline: none;
      border-color: $clp-datepicker-border-color !important;
    }

    .ant-input-disabled,
    .ant-input-disabled + .anticon {
      opacity: 0.5;
    }

    &_readonly {
      .ant-input-disabled,
      .ant-input-disabled + .anticon {
        opacity: 1;
      }
    }
  }
}

.clp-mob-datepicker {
  .am-picker-popup-item {
    color: $color-primary;
  }
}
