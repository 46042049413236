$img-sign-half-space-after-content: 20px;

.img-sign {
  margin-top: 50px;
  width: calc(50vw - #{$img-sign-half-space-after-content});
}

.svg-icon {
  &_black {
   path {
     color: black;
   } 
  }

  &_gray {
    path {
      color: $color-main-2;
    }
  }
  
  &_center {
    vertical-align: middle;
  }
  
  &_size-21 {
    font-size: 21px;
    line-height: 21px;
  }
}