$border-color: $color-border-light;
$footer-color: rgba(0, 0, 0, 0.65);

.clw-table {
  .ant-table-thead {
    tr {
      th {
        border-color: $border-color;
        background: $color-table-header;

        &:not(:last-child) {
          border-right: none;
        }

        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
  
  .ant-table-footer {
    border-color: $border-color !important;
    border-radius: 0;
  }

  .ant-table-tbody {
    tr {
      &:hover {
        td {
          background: transparent !important;
        }
      }

      td {
        border-color: $border-color;

        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
  
  &_align-left {
    .ant-table-tbody {
      tr {
        td {
          &:not(:first-child) {
            text-align: left;
          }
        }
      }
    }

    .ant-table-thead {
      tr {
        th {
          &:not(:first-child) {
            text-align: left;
          }
        }
      }
    }
  }

  &_without-no-data {
    .ant-table-placeholder {
      display: none;
    }
  }

  &_first-col-bold {
    .ant-table-tbody {
      td {
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }

  &_last-row-bold {
    tr {
      &:last-child {
        font-weight: bold;
      }
    }
  }

  .ant-table-bordered .ant-table-body > table {
    border-color: $border-color;
  }
}

.clw-table-footer {
  color: $footer-color;
  text-align: center;
  line-height: 22px;
}

.clw-table-footer-link {
  @extend .link;

  font-weight: bold;
  letter-spacing: unset !important;
}

.ant-table-column-sorter {
  .ant-table-column-sorter-up, .ant-table-column-sorter-down {
    &.off path {
      opacity: 0.4;
    }
  }
}
