.les-card {
  @extend .border-radius-2;

  padding: $spacer-4 $spacer-6;
  background: $color-card-bg;
  border: 1px solid $color-border-light;
  box-sizing: border-box;

  & > * {
    &:not(:first-child) {
      padding-top: $spacer-3;
    }

    &:not(:last-child) {
      padding-bottom: $spacer-2;
    }
  }
}
