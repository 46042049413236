.contribution-form {
  position: relative;

  @media (min-width: $screen-md) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: calc(100% - 25px);
      background: $color-divider-shadow;
    }

    &_line_full {
      &::after {
        height: 100%;
      }
    }
  }

  @media (min-width: $screen-md) {
    padding-left: 15px;
  }
}
