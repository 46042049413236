$label-font-size: $font-size-md;

.breadcrumb {
  font-size: $label-font-size;

  &__label {
    &_inactive {
      color: $color-label-inactive;

      svg {
        fill: $color-label-inactive;
      }
    }
  }
}
