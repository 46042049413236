$screen-md: 768px;
$screen-lg: 1024px;

$color-primary: $color-main-1;

$border-radius-spacers: 2 10;
@include fixedSpacerBuilder(border-radius, $border-radius-spacers);

$spacer: 8px;

$spacer-0: $spacer * 0;
$spacer-1: $spacer / 4;
$spacer-2: $spacer / 2;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 2;
$spacer-6: $spacer * 2.5;
$spacer-7: $spacer * 3;
$spacer-8: $spacer * 3.5;
$spacer-9: $spacer * 4;
$spacer-10: $spacer * 4.5;
$spacer-11: $spacer * 5;
$spacer-12: $spacer * 5.5;
$spacer-13: $spacer * 6;
$spacer-14: $spacer * 6.5;
$spacer-15: $spacer * 7;
$spacer-16: $spacer * 7.5;

$font-merriweather: 'Merriweather', serif;
$font-nova: proxima-nova, sans-serif;

$color-border: #e5e5e5;
$color-border-light: #b6b4b4;
$color-card-bg: #fafafa;
$color-label-inactive: #c0c0c0;
$color-label-light: #979797;
$color-table-header: #ededed;

$font-size-md: 18px;

$color-shadow: rgba(0, 0, 0, 0.08);
$color-divider-shadow: #0f0d184d;

$color-antd-error: #f5222d;

$color-font-dark-2: #2b2b2b;
$color-font-default: #9c9c9c;

$font-size-sm: 12px;
$font-size-xs: 10px;
$font-size-logo-header: 24px;
