$day-off-color: #000;
$day-off-color-selected: #fff;
$day-off-color-disabled: #bbb;

.am-calendar .header .right {
  display: none;
}

.am-calendar .single-month .row .cell .date-wrapper .date-selected {
  background: $color-primary;
}

.am-calendar .confirm-panel .button {
  background: $color-primary;
}

.am-calendar .single-month .row .cell .date-wrapper .grey {
  color: $day-off-color;

  &.date-selected {
    color: $day-off-color-selected;
  }

  &.disable {
    color: $day-off-color-disabled;
  }
}

.am-calendar .week-panel .cell-grey {
  color: $day-off-color;
}
