@import '../variables';
$max-w-desktop: 1024px;

.les-plane {
  width: 100%;
  display: block;
  margin: 0 auto;

  &_desk {
    max-width: 1084px;
  }

  &__ribbon {
    @media (max-width: $max-w-desktop) {
      font-size: 11px;
    }
    font-size: 14px;

    font-family: "Satisfy", cursive;
    position: absolute;
    top: calc(54%);
    left: calc(50% - 290px);
    width: 365px;
    text-align: center;
    transform: translateY(-50%);
  }
}
