$max-w-mobile: 60vw;
$max-w-desktop: 320px;

.clp-footer {
  overflow: initial;
  padding-bottom: $spacer-11;

  &__img {
    max-width: $max-w-mobile;

    top: -40px;
    right: 0;

    display: none;

    &_mob {
      display: block;
    }

    @media (min-width: $screen-md) {
      max-width: $max-w-desktop;

      right: 0;
      bottom: 0;
      top: 10px;

      display: block;

      &_mob {
        display: none;
      }
    }
  }
}
