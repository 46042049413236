$height-for-email-input: 29px;

a.terms-and-gdrp-links {
  color: $color-main-1;
  text-decoration: underline;
}

.email-input {
  & .clp-input.ant-input {
    max-height: $height-for-email-input;
  }
}
