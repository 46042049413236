$divider-width: 38px;
$space-between-inputs: 2px;

.range-inputs-item {
  width: calc(50% - #{$divider-width / 2} + #{$space-between-inputs});
}

.range-inputs-divider {
  width: $divider-width;
  text-align: center;
  margin-left: -$space-between-inputs;
  margin-right: -$space-between-inputs;
  opacity: 1 !important;
}
