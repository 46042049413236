$logo-link-width-mob: 230px;
$logo-link-height-mob: 75px;

$logo-link-width-desk: 346px;
$logo-link-height-desk: 114px;

.logo-link {
  position: absolute;
  padding-top: $spacer-7;

  svg {
    max-height: $logo-link-height-mob;
    max-width: $logo-link-width-mob;
    width: 100%;

    @media (min-width: $screen-md) {
      max-height: $logo-link-height-desk;
      max-width: $logo-link-width-desk;
      width: 100%;
    }
  }
}
