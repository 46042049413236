.clp-phone-input__container {
  .selected-flag {
    outline: none;
  }

  .country-list {
    .divider {
      opacity: initial;
      background: transparent;
      margin-top: 0;
    }
  }
}

